import React from 'react';
import { Button } from '@amzn/stencil-react-components/button';
import { Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import {unSubscribedMessages, supportedLocale} from "../localeContent";

const Headline = Text.withComponent('h1');
let localePreference: string = "en_US";
if(typeof window !== 'undefined'){
    localePreference = (new URLSearchParams(window.location.search).get('locale') as string);
    if (supportedLocale.indexOf(localePreference) < 0 ){
        localePreference = "en_US";
    }
    var localSpecificMessages =  JSON.parse(JSON.stringify(unSubscribedMessages));
    var content = localSpecificMessages[localePreference];
}
export type UnsubscribedStateProps = {
    onClickSubscribe: () => void;
};
export default function UnsubscribedState(props: UnsubscribedStateProps) {
    return (
        <div>
            <Row alignItems="center" justifyContent="center">
                <Headline fontSize="T600">{content.headline}</Headline>
            </Row>
            <Row alignItems="center" justifyContent="center" margin="1rem">
                <Text fontSize="T300">
                    {content.body1}
                </Text>
            </Row>
            <Row alignItems="center" justifyContent="center" margin="1rem">
                <Text fontSize="T300">
                    {content.body2}
                </Text>
            </Row>
            <Row alignItems="center" justifyContent="center">
                <Button primary onClick={props.onClickSubscribe}>
                    {content.buttonMsg}
                </Button>
            </Row>
        </div>
    );
}
import { getSearch } from '../window';

export const getEndpointId = (): string | undefined => {
  try {
    const params = new URLSearchParams(getSearch().substring(1));
    return params.get('endpointId') || undefined;
  } catch (e) {
    // IE
    const match = getSearch().match(/endpointId\=(\w|-)+/)?.[0];

    if (match) {
      return match.replace('endpointId=', '');
    }

    return undefined;
  }
};

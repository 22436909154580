import { getSearch } from '../window';

/**
 * encodedEndpointId will be a base64 encoded string that could possibly have url unsafe characters
 *
 * the URLSearchParams object doesn't handle base64 query params very well so going to use regex to pull
 * out the info we need
 *
 * Sample URL: https://optout.gamma-optout.ocoms.associate.amazondelivers.jobs/?encodedEndpointId=dGVzdEVuZHBvaW50SWQ=
 */
export const getEncodedEndpointId = (): string | undefined => {
  const match = getSearch().match(/encodedEndpointId=(\w|-|=|\+)+/)?.[0];

  if (match) {
    return match.replace('encodedEndpointId=', '');
  }

  return undefined;
};

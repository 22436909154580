import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import type { SimpleProviderProps } from './util';

type Settings = Record<string, unknown>;

type SettingsContextValue = {
  settings: Settings | null;
  error: Error | null;
};

const SettingsContext = createContext<SettingsContextValue>({
  settings: null,
  error: null,
});

const useSettingsRequest = (): [Settings | null, Error | null] => {
  const [settings, setSettings] = useState<Settings | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let current = true;

    axios
      .get<Settings>('/settings.json', {
        responseType: 'json',
      })
      .then((response) => {
        // We want to test useState directly and not worry about istanbuls opinions on hook cleanup
        /* istanbul ignore next */
        if (!current) return;
        setSettings(response.data);
      })
      .catch((e) => {
        /* istanbul ignore next */
        if (!current) return;
        setError(e);
      });

    return () => {
      /* istanbul ignore next */
      current = false;
    };
  }, [setSettings, setError]);

  return [settings, error];
};

export const SettingsProvider = (props: SimpleProviderProps) => {
  const [settings, error] = useSettingsRequest();

  const contextValue = {
    settings,
    error,
  };

  return (
    <SettingsContext.Provider value={contextValue}>
      {props.children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);

import React, { createContext, useContext, useMemo } from 'react';
import axios, { AxiosInstance } from 'axios';
import { useSettings } from './settings';
import type { SimpleProviderProps } from './util';

type OptOutClientContextValue = {
  client: AxiosInstance | null;
  error: Error | null;
};

const OptOutClientContext = createContext<OptOutClientContextValue>({
  client: null,
  error: null,
});

export const OptOutApiClientProvider = (props: SimpleProviderProps) => {
  const { settings, error } = useSettings();

  const client = useMemo(() => {
    if (typeof settings?.gateway !== 'string') return null;

    return axios.create({
      baseURL: settings.gateway,
      timeout: 5000,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }, [settings]);

  const contextValue = {
    client,
    error,
  };

  return (
    <OptOutClientContext.Provider value={contextValue}>
      {props.children}
    </OptOutClientContext.Provider>
  );
};

export const useOptOutApiClient = () => useContext(OptOutClientContext);

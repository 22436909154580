import React from 'react';
import { Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import {reSubscribedMessages, supportedLocale} from "../localeContent"; 

const Headline = Text.withComponent('h1');

if(typeof window !== 'undefined'){
  let localePreference = (new URLSearchParams(window.location.search).get ('locale') as string);
  if (supportedLocale.indexOf(localePreference) < 0 )
      localePreference = "en_US";
    
  var localSpecificMessages =  JSON.parse(JSON.stringify(reSubscribedMessages));
  var content = localSpecificMessages[localePreference];
}

export default function ResubscribedState() {
  return (
    <div>
      <Row alignItems="center" justifyContent="center">
        <Headline fontSize="T600">{content.headline}</Headline>
      </Row>
      <Row alignItems="center" justifyContent="center" margin="1rem">
        <Text fontSize="T300">
            {content.body1}
        </Text>
      </Row>
    </div>
  );
}

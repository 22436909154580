export const supportedLocale = ["en_US", "es_US", "en_CA", "fr_CA", "ja_JP", "es_MX", "en_GB", "de_DE", "en_DE" ];

export const unSubscribedMessages = {
    en_US: {
        headline: "Success! You are unsubscribed.",
        body1: "You will no longer receive email notifications related to jobs with Amazon.",
        body2: "If you did not mean to unsubscribe or still want to receive these emails, you can re-subscribe.",
        buttonMsg: "Subscribe"
    },
    en_CA: {
        headline: "Success! You are unsubscribed.",
        body1: "You will no longer receive email notifications related to jobs with Amazon.",
        body2: "If you did not mean to unsubscribe or still want to receive these emails, you can re-subscribe.",
        buttonMsg: "Subscribe"
    },
    fr_CA: {
        headline: "C'est fait ! Vous êtes désabonné.",
        body1: "Vous ne recevrez plus de notifications par courriel concernant les emplois chez Amazon.",
        body2: "Si vous n'avez pas voulu vous désabonner ou si vous souhaitez toujours recevoir ces courriels, vous pouvez vous réabonner.",
        buttonMsg: "S'abonner"
    },
    en_GB: {
        headline: "Success! You are unsubscribed.",
        body1: "You will no longer receive email notifications related to jobs with Amazon.",
        body2: "If you did not mean to unsubscribe or still want to receive these emails, you can re-subscribe.",
        buttonMsg: "Subscribe"
    },
    es_MX: {
        headline: "Success! You are unsubscribed.",
        body1: "You will no longer receive email notifications related to jobs with Amazon.",
        body2: "If you did not mean to unsubscribe or still want to receive these emails, you can re-subscribe.",
        buttonMsg: "Subscribe"
    },
    es_US: {
        headline: "Success! You are unsubscribed.",
        body1: "You will no longer receive email notifications related to jobs with Amazon.",
        body2: "If you did not mean to unsubscribe or still want to receive these emails, you can re-subscribe.",
        buttonMsg: "Subscribe"
    },
    ja_JP: {
        headline: "配信の停止が完了しました。",
        body1: "今後、Amazonからの応募に関連するメールは送信されません。",
        body2: "Amazonの求人の最新情報を、再度受け取りたい場合は、再登録することが可能です。",
        buttonMsg: "登録する"
    },
    de_DE: {
        headline: "Deine Abmeldung war erfolgreich!",
        body1: "Ab sofort wirst du keine E-Mails mehr im Bezug auf Stellenangebote bei Amazon erhalten.",
        body2: "Falls du dich aus Versehen abgemeldet hast oder E-mails dieser Art weiterhin erhalten möchtest, kannst du dich jederzeit wieder anmelden.",
        buttonMsg: "Anmelden"
    },
    en_DE: {
        headline: "Success! You are unsubscribed.",
        body1: "You will no longer receive email notifications related to jobs with Amazon.",
        body2: "If you did not mean to unsubscribe or still want to receive these emails, you can re-subscribe.",
        buttonMsg: "Subscribe"
    }
};

export const reSubscribedMessages = {
    en_US: {
        headline: "You have re-subscribed.",
        body1: "You have re-subscribed. You will continue receiving emails related to your application."
    },
    en_GB: {
        headline: "You have re-subscribed.",
        body1: "You have re-subscribed. You will continue receiving emails related to your application."
    },
    en_CA: {
        headline: "You have re-subscribed.",
        body1: "You have re-subscribed. You will continue receiving emails related to your application."
    },
    fr_CA: {
        headline: "Vous vous êtes réabonné.",
        body1: "Vous vous êtes réabonné. Vous continuerez à recevoir des courriels relatifs à votre demande."
    },
    es_MX: {
        headline: "You have re-subscribed.",
        body1: "You have re-subscribed. You will continue receiving emails related to your application."
    },
    es_US: {
        headline: "You have re-subscribed.",
        body1: "You have re-subscribed. You will continue receiving emails related to your application."
    },
    ja_JP: {
        headline: "メール購読の再登録が完了しました",
        body1: "Amazonの採用に関連するメールが、引き続き配信されます。"
    },
    de_DE: {
        headline: "Du hast dich wieder angemeldet.",
        body1: "Du wirst weiterhin E-Mails im Bezug auf Stellenangebote bei Amazon erhalten."
    },
    en_DE: {
        headline: "You have re-subscribed.",
        body1: "You have re-subscribed. You will continue receiving emails related to your application."
    }
};

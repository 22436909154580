import { useState, useEffect, useMemo } from 'react';
import { useOptOutApiClient } from '../../context/optOutApiClient';
import { getEndpointId } from '../../util/getEndpointId';
import { SafeError } from '../../errors';
import {getEncodedEndpointId} from "../../util/getEncodedEndpointId";

const initialErrorState = () =>
  typeof window === 'undefined' || getEndpointId() || getEncodedEndpointId()
    ? null
    : new SafeError('Unsubscribe information missing.');

export const INITIALIZING = 'initializing';
export const UNSUBSCRIBED = 'unsubscribed';
export const RESUBSCRIBING = 'resubscribing';
export const RESUBSCRIBED = 'resubscribed';

/**
 * Key indicating the current state of the preferences form.
 * Users progress through each of the states linearly
 */
export type SubscriptionState =
  | typeof INITIALIZING
  | typeof UNSUBSCRIBED
  | typeof RESUBSCRIBING
  | typeof RESUBSCRIBED;

const getOptOutStatus = (optIn: boolean) =>
  optIn
    ? 'NONE' // They are opting in, so they opt out of NONE messages
    : 'ALL'; // The are opting out, so they opt out of ALL messages

const useRequest = (optIn: boolean) => {
  const { client } = useOptOutApiClient();

  return useMemo(() => {
    if (!client) return null;

    return () =>
      client.post('/opt-out', {
        endpointId: getEndpointId(),
        optOut: getOptOutStatus(optIn),
        encodedEndpointId: getEncodedEndpointId()
      });
  }, [client, optIn]);
};

type UseUnsubscribeHookData = {
  onSubmit: () => void;
  currentState: SubscriptionState;
  error: Error | null;
};

export const useUnsubscribe = (): UseUnsubscribeHookData => {
  const { error: loadingError } = useOptOutApiClient();

  const [currentState, setStateKey] = useState<SubscriptionState>(INITIALIZING);
  const [error, setError] = useState<Error | null>(initialErrorState);
  const request = useRequest(currentState === UNSUBSCRIBED);

  const requestDependencies = [
    request,
    currentState,
    error,
    setStateKey,
    setError,
  ];

  // Initial Unsub loop
  useEffect(() => {
    if (currentState !== INITIALIZING || error || !request) return;

    request()
      .then(() => {
        setStateKey(UNSUBSCRIBED);
      })
      .catch((e) => {
        setError(e);
      });
  }, requestDependencies);

  const handler = useMemo(() => {
    if (currentState !== UNSUBSCRIBED || error || !request) {
      return () => {};
    }

    return () => {
      setStateKey(RESUBSCRIBING);

      request()
        .then(() => {
          setStateKey(RESUBSCRIBED);
        })
        .catch((e) => {
          setError(e);
          setStateKey(UNSUBSCRIBED);
        });
    };
  }, requestDependencies);

  return {
    onSubmit: handler,
    currentState,
    error: loadingError || error,
  };
};

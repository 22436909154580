import React from 'react';
import { PageContainer } from '@amzn/stencil-react-components/page';

import { SettingsProvider } from '../context/settings';
import { OptOutApiClientProvider } from '../context/optOutApiClient';
import Header from '../components/header';
import Preferences from '../components/preferences';

export default function OptoutFlight() {
  return (
    <SettingsProvider>
      <OptOutApiClientProvider>
        <Header />
        <PageContainer>
          <Preferences />
        </PageContainer>
      </OptOutApiClientProvider>
    </SettingsProvider>
  );
}

import React from 'react';
import {
  MessageBanner,
  MessageBannerType,
} from '@amzn/stencil-react-components/message-banner';
import { Row } from '@amzn/stencil-react-components/layout';
import { Spinner } from '@amzn/stencil-react-components/spinner';
import {
  useUnsubscribe,
  SubscriptionState,
  UNSUBSCRIBED,
  RESUBSCRIBED,
} from './preferences/hooks';
import UnsubscribedState from './preferences/unsubscribedState';
import ResubscribedState from './preferences/resubscribedState';
import { getSafeErrorMessage } from '../errors';

function PreferenceBody({
  subscriptionState,
  onSubmit,
}: {
  subscriptionState: SubscriptionState;
  onSubmit: () => void;
}) {
  switch (subscriptionState) {
    case UNSUBSCRIBED:
      return <UnsubscribedState onClickSubscribe={onSubmit} />;
    case RESUBSCRIBED:
      return <ResubscribedState />;
    default:
      return <Spinner />;
  }
}

export default function Preferences() {
  const { currentState, error, onSubmit } = useUnsubscribe();

  const renderError = error ? (
    <Row alignItems="center" justifyContent="center">
      <MessageBanner type={MessageBannerType.Error}>
        {getSafeErrorMessage(error)} <br />
        Please try the link in your email again.
      </MessageBanner>
    </Row>
  ) : null;

  return (
    <>
      {renderError}
      <Row alignItems="center" justifyContent="center">
        <PreferenceBody subscriptionState={currentState} onSubmit={onSubmit} />
      </Row>
    </>
  );
}
